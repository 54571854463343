<template>
  <div class="limites_cadastro_background">
    <section class="container">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <v-col class="d-flex justify-center align-center">
          <img
            width="50%"
            src="../../assets/conteudos_violacoes_direitos_title_cover.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>
    <section class="container d-flex justify-center align-center flex-column">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="6" md="2" class="d-none d-md-flex">
          <img
            src="../../assets/conteudos_violacoes_direitos_inicio_01.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2">
          <img
            @click="goToPage('conteudos_tematicos_limitacao_cadastro')"
            src="../../assets/conteudos_violacoes_direitos_inicio_02.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2">
          <img
            @click="goToPage('conteudos_tematicos_desconsideracao_cadastro')"
            src="../../assets/conteudos_violacoes_direitos_inicio_03.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2" class="d-none d-md-flex">
          <img
            src="../../assets/conteudos_violacoes_direitos_inicio_01.png"
            alt=""
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="6" md="2" class="d-none d-md-flex">
          <img
            src="../../assets/conteudos_violacoes_direitos_inicio_01.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2">
          <img
            @click="goToPage('conteudos_tematicos_descumprimento_prazos')"
            src="../../assets/conteudos_violacoes_direitos_inicio_04.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2">
          <img
            @click="
              goToPage('conteudos_tematicos_violacoes_parcialidade_mediacao')
            "
            src="../../assets/conteudos_violacoes_direitos_inicio_05.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2">
          <img
            @click="goToPage('conteudos_tematicos_violacoes_indenizacao_terra')"
            src="../../assets/conteudos_violacoes_direitos_inicio_06.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2" class="d-none d-md-flex">
          <img
            src="../../assets/conteudos_violacoes_direitos_inicio_01.png"
            alt=""
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="6" md="2" class="d-none d-md-flex">
          <img
            src="../../assets/conteudos_violacoes_direitos_inicio_01.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2">
          <img
            @click="goToPage('conteudos_tematicos_violacoes_dano_moral')"
            src="../../assets/conteudos_violacoes_direitos_inicio_07.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2">
          <img
            @click="goToPage('conteudos_tematicos_violacoes_impossibilidade')"
            src="../../assets/conteudos_violacoes_direitos_inicio_08.png"
            alt=""
          />
        </v-col>
        <v-col cols="6" md="2" class="d-none d-md-flex">
          <img
            src="../../assets/conteudos_violacoes_direitos_inicio_01.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb.vue";
export default {
  components: { Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Violações de Direito",
          href: "conteudos_tematicos_violacoes_direitos",
        },
      ],
    };
  },
  methods: {
    goToPage(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style scoped>
.limites_cadastro_background {
  background-image: url("../../assets/conteudos_violacoes_direitos_background.png");
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f9f7ed;
}
</style>
